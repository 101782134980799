@use '/src/assets/new_variables.scss' as *;

.homepageSliderGallery {
  position: relative;

  &.back-stage {
    padding-bottom: 43.25%;
    background-color: $color-border-gray;

    @media screen and (max-width: 768px),
      screen and (orientation: landscape) and (max-width: 768px),
      screen and (orientation: portrait) and (max-width: 768px) {
      padding-bottom: unset;
      background-color: unset;
    }
  }

  .fake-menu {
    height: 149px;
    width: 100%;
    position: absolute;
    background: red;
    top: 0px;
    z-index: 100;
    @media screen and (max-width: 1280px) {
      height: 100px;
    }
  }
  .quick-parent {
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 1920px;
    margin: auto;
    @media screen and (max-width: 768px) {
      max-width: 768px;
    }
    .quick-actions {
      background: transparent;
      height: auto;
      left: auto;
      right: 0;
      margin-top: 300px;
      margin-right: 45px;
      position: absolute;
      top: unset;
      transform: unset;
      width: 450px;
      z-index: auto;
      .history {
        @media screen and (max-width: 428px) {
          height: auto;
        }
      }
      @media screen and (min-width: 1600px) and (max-width: 1919px) {
        margin-top: 195px;
      }
      @media screen and (min-width: 1280px) and (max-width: 1600px) {
        margin-top: 158px;
      }
      @media screen and (min-width: 991px) and (max-width: 1279px) {
        width: 400px;
        margin-top: 150px;
      }
      @media screen and (max-width: 990px),
        screen and (orientation: landscape) and (max-width: 990px),
        screen and (orientation: portrait) and (max-width: 990px) {
        width: 500px;
        left: 0;
        right: 0;
        margin: 300px auto 0 auto;
      }
      @media screen and (max-width: 576px) {
        width: 100%;
        padding: 0 30px;
        margin-top: 268px;
      }
      @media screen and (max-width: 428px) {
        margin: 260px auto 0 auto;
        padding: 0 20px;
      }
      @media screen and (max-width: 380px) {
        padding: 0 15px;
      }
    }
  }
}
